.btn-metamask, .btn-bnbwallet, .btn-eth, .btn-bnb {
    display: flex;
    background: white;
    border-radius: 5px;
    border: 1px solid #e5e7eb!important;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    align-items: center;
}

.btn-metamask:hover, .btn-bnbwallet:hover, .btn-eth:hover, .btn-bnb:hover {
    color: #fff;
    background-color: #4c40ed;
}