@charset "UTF-8";
/* html, body {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Nunito", sans-serif;
  color: #000;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
} */

#form-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

form {
    /* width: 90%;
  max-width: 500px; */
    width: 100%;
}

form #form-title {
    margin-top: 0;
    font-weight: 400;
    text-align: center;
}

form #debt-amount-slider {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    position: relative;
    width: 100%;
    height: 50px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

form #debt-amount-slider::before {
    content: " ";
    position: absolute;
    height: 2px;
    width: 100%;
    /* width: calc(100% * (4 / 5)); */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #5045ec;
}

form #debt-amount-slider input,
form #debt-amount-slider label {
    box-sizing: border-box;
    flex: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

form #debt-amount-slider label {
    display: inline-block;
    position: relative;
    width: 20%;
    height: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

form #debt-amount-slider label::before {
    content: attr(data-debt-amount);
    position: absolute;
    left: 50%;
    padding-top: 10px;
    transform: translate(-50%, 45px);
    font-size: 14px;
    letter-spacing: 0.4px;
    font-weight: 400;
    white-space: nowrap;
    opacity: 0.85;
    transition: all 0.15s ease-in-out;
}

form #debt-amount-slider label::after {
    content: " ";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    border: 2px solid #5045ec;
    background: #fff;
    border-radius: 50%;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 1;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
}

form #debt-amount-slider label:hover::after {
    transform: translate(-50%, -50%) scale(1.25);
}

form #debt-amount-slider input {
    display: none;
}

form #debt-amount-slider input:checked+label::before {
    font-weight: 800;
    opacity: 1;
}

form #debt-amount-slider input:checked+label::after {
    background: #5045ec;
    border-width: 4px;
    transform: translate(-50%, -50%) scale(0.75);
}

form #debt-amount-slider input:checked~#debt-amount-pos {
    opacity: 1;
}

form #debt-amount-slider input:checked:nth-child(1)~#debt-amount-pos {
    left: 10%;
}

form #debt-amount-slider input:checked:nth-child(3)~#debt-amount-pos {
    left: 30%;
}

form #debt-amount-slider input:checked:nth-child(5)~#debt-amount-pos {
    left: 50%;
}

form #debt-amount-slider input:checked:nth-child(7)~#debt-amount-pos {
    left: 70%;
}

form #debt-amount-slider input:checked:nth-child(9)~#debt-amount-pos {
    left: 90%;
}

form #debt-amount-slider #debt-amount-pos {
    display: block;
    position: absolute;
    top: 50%;
    width: 12px;
    height: 12px;
    background: #5045ec;
    border-radius: 50%;
    transition: all 0.15s ease-in-out;
    transform: translate(-50%, -50%);
    border: 2px solid #fff;
    opacity: 0;
    z-index: 2;
}

form:valid #debt-amount-slider input+label::before {
    transform: translate(-50%, 45px) scale(0.9);
    transition: all 0.15s linear;
}

form:valid #debt-amount-slider input:checked+label::before {
    color: #5045ec;
    transform: translate(-50%, 45px) scale(1.1);
    transition: all 0.15s linear;
}

form+button {
    display: block;
    position: relative;
    margin: 56px auto 0;
    padding: 10px 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: all 0.15s ease-in-out;
    font-family: inherit;
    font-size: 24px;
    font-weight: 600;
    background: #fff;
    border: 2px solid #000;
    border-radius: 8px;
    outline: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

form+button:hover {
    background: #000;
    color: #fff;
}

form+button:hover:active {
    transform: scale(0.9);
}

form+button:focus {
    background: #4caf50;
    border-color: #4caf50;
    color: #fff;
    pointer-events: none;
}

form+button:focus::before {
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

form+button::before {
    display: inline-block;
    width: 0;
    opacity: 0;
    content: "";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    margin-right: 0;
    transform: rotate(0deg);
}

form:invalid+button {
    pointer-events: none;
    opacity: 0.25;
}

@-webkit-keyframes spin {
    from {
        transform: rotate(0deg);
        width: 24px;
        opacity: 1;
        margin-right: 12px;
    }

    to {
        transform: rotate(360deg);
        width: 24px;
        opacity: 1;
        margin-right: 12px;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
        width: 24px;
        opacity: 1;
        margin-right: 12px;
    }

    to {
        transform: rotate(360deg);
        width: 24px;
        opacity: 1;
        margin-right: 12px;
    }
}