.customer-review-top-white::before {
    position: absolute;
    content: '';
    bottom: -2px;
    left: 50%;
    width: 54px;
    height: 2px;
    background: white;
    transform: translate(-50%, -50%);
}

.customer-review-top {
    position: relative;
    padding: 20px 56px;
    text-align: center;
    /* border-bottom: 2px solid #e7e7e7; */
    border-bottom: 0;

}