.heading-style {
    white-space: nowrap;
    color: white;
    font-size: 26px;
    font-weight: bold;
    opacity: 1;
    padding: 0;
    background: none;
    background-size: auto 1em;
    background-repeat: repeat-x;
    background-position: center 50%;
    /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000' height='89.68'%3E%3Cpath fill='%2361daff' d='M0 0h1000v89.68H0z'/%3E%3C/svg%3E"); */
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35.67' height='89.68'%3E%3Cpath d='M35.67 0h-9.36C11 2.08 6.36 6.35 6.36 10.35v5.75c0 7.85 2.85 14 2.85 21.84 0 1.56-2.16 5.37-2.85 6.9-2.1 4.62-12.36 8.25-1.3 13.65C10.67 61.23.66 65.44.66 69c0 4 2.85 8.61 2.85 12.65 0 2.62.09 5.27 11.4 8.05h20.76z' fill='%234c40ed' fill-rule='evenodd'/%3E%3C/svg%3E"), url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000' height='89.68'%3E%3Cpath fill='%234c40ed' d='M0 0h1000v89.68H0z'/%3E%3C/svg%3E");

    /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000' height='89.68'%3E%3Cpath fill='%2300baff' d='M0 0h1000v89.68H0z'/%3E%3C/svg%3E"); */
}

/**before**/
.heading-style.styled.aqua:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35.67' height='89.68'%3E%3Cpath d='M35.67 0h-9.36C11 2.08 6.36 6.35 6.36 10.35v5.75c0 7.85 2.85 14 2.85 21.84 0 1.56-2.16 5.37-2.85 6.9-2.1 4.62-12.36 8.25-1.3 13.65C10.67 61.23.66 65.44.66 69c0 4 2.85 8.61 2.85 12.65 0 2.62.09 5.27 11.4 8.05h20.76z' fill='%234c40ed' fill-rule='evenodd'/%3E%3C/svg%3E"), url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000' height='89.68'%3E%3Cpath fill='%234c40ed' d='M0 0h1000v89.68H0z'/%3E%3C/svg%3E")
}

.heading-style.styled:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35.67' height='89.68'%3E%3Cpath d='M35.67 0h-9.36C11 2.08 6.36 6.35 6.36 10.35v5.75c0 7.85 2.85 14 2.85 21.84 0 1.56-2.16 5.37-2.85 6.9-2.1 4.62-12.36 8.25-1.3 13.65C10.67 61.23.66 65.44.66 69c0 4 2.85 8.61 2.85 12.65 0 2.62.09 5.27 11.4 8.05h20.76z' fill='%2361daff' fill-rule='evenodd'/%3E%3C/svg%3E"), url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000' height='89.68'%3E%3Cpath fill='%2361daff' d='M0 0h1000v89.68H0z'/%3E%3C/svg%3E")
}

@supports (--css: variables) {
    .heading-style.styled:before {
        margin-left: calc(-0.007ch*var(--mark-w, 100) - -0.003ch*var(--mark-indent, 0));
        padding-right: calc(0.007ch*var(--mark-w, 100) + -0.003ch*var(--mark-indent, 0));
        background-position: left 0 top calc(50% + 0.1%*var(--mark-offset, 0)), left calc(0.01em*var(--mark-h, 100)*36/90 - 1px) top calc(50% + 0.1%*var(--mark-offset, 0))
    }
}

.heading-style.styled:before {
    margin-left: -.7ch;
    padding-right: .7ch;
    background-repeat: no-repeat, no-repeat;
    background-size: inherit;
    background-position: left 0 top 50%, left calc(.4em - 1px) top 50%
}

.heading-style.styled:after,
.heading-style.styled:before {
    display: inline;
    color: white;
    content: "\00FEFF";
    position: relative;
    /* z-index: -1 */
}

/**before**/


/**after **/
.heading-style.styled.aqua:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40.25' height='89.68'%3E%3Cpath d='M26 32.19c0-1.7 14.25-7.5 14.25-9.19 0-1.46-19.95-2.45-19.95-3.91 0-2.29 17.1-7.4 17.1-9.89C37.4 5.33 41 2 20.3 0H0v89.68h20.3c4.09 0 8.3-4.15 8.3-6.44 0-1.64-8.3-4.41-8.3-5.35 0-3.09 14.25-5.77 14.25-8.91 0-1.09-14.25-2.69-14.25-3.79 0-4 11.4-9.47 11.4-13.45 0-3.7-11.4-5.89-11.4-9.59 0-4.5 5.7-5.46 5.7-9.96z' fill='%234c40ed' fill-rule='evenodd'/%3E%3C/svg%3E"), url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000' height='89.68'%3E%3Cpath fill='%234c40ed' d='M0 0h1000v89.68H0z'/%3E%3C/svg%3E")
}

.heading-style.styled:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40.25' height='89.68'%3E%3Cpath d='M26 32.19c0-1.7 14.25-7.5 14.25-9.19 0-1.46-19.95-2.45-19.95-3.91 0-2.29 17.1-7.4 17.1-9.89C37.4 5.33 41 2 20.3 0H0v89.68h20.3c4.09 0 8.3-4.15 8.3-6.44 0-1.64-8.3-4.41-8.3-5.35 0-3.09 14.25-5.77 14.25-8.91 0-1.09-14.25-2.69-14.25-3.79 0-4 11.4-9.47 11.4-13.45 0-3.7-11.4-5.89-11.4-9.59 0-4.5 5.7-5.46 5.7-9.96z' fill='%2361daff' fill-rule='evenodd'/%3E%3C/svg%3E"), url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000' height='89.68'%3E%3Cpath fill='%2361daff' d='M0 0h1000v89.68H0z'/%3E%3C/svg%3E")
}

@supports (--css: variables) {
    .heading-style.styled:after {
        margin-right: calc(-0.007ch*var(--mark-w, 100) + -0.003ch*var(--mark-indent, 0));
        padding-right: calc(0.007ch*var(--mark-w, 100) - -0.003ch*var(--mark-indent, 0));
        background-position: right 0 top calc(50% + 0.1%*var(--mark-offset, 0)), right calc(0.01em*var(--mark-h, 100)*40/90 - 1px) top calc(50% + 0.1%*var(--mark-offset, 0))
    }
}

.heading-style.styled:after {
    margin-right: -.7ch;
    padding-right: .7ch;
    background-repeat: no-repeat, no-repeat;
    background-size: inherit;
    background-position: right 0 top 50%, right calc(.44444em - 1px) top 50%
}

.heading-style.styled:after,
.heading-style.styled:before {
    display: inline;
    color: white;
    content: "\00FEFF";
    position: relative;
    /* z-index: -1 */
}

/**after **/

@media only screen and (max-width:575.98px) {
    .heading-style {
        font-size: 20px;
    }
}

.read-more {
    font-weight: 500;
    font-size: 16px;
    color: #4c40ed;
    background: #F7F7FF;
    border-radius: 4px;
    padding: 10px 15px;
    display: inline-flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
}

.read-more:hover {
    background: #4c40ed;
    color: #fff;
}