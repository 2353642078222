/* .slidingHorizontal span {
    animation: leftToRight 12.5s linear infinite 0s;
    -ms-animation: leftToRight 12.5s linear infinite 0s;
    -webkit-animation: leftToRight 12.5s linear infinite 0s;
    color: #00abe9;
    opacity: 0;
    overflow: hidden;
    position: absolute;
}

.slidingHorizontal span:nth-child(2) {
    animation-delay: 2.5s;
    -ms-animation-delay: 2.5s;
    -webkit-animation-delay: 2.5s;
}

.slidingHorizontal span:nth-child(3) {
    animation-delay: 5s;
    -ms-animation-delay: 5s;
    -webkit-animation-delay: 5s;
}

@-moz-keyframes leftToRight {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 0;
        -moz-transform: translateX(-50px);
    }
    10% {
        opacity: 1;
        -moz-transform: translateX(0px);
    }
    25% {
        opacity: 1;
        -moz-transform: translateX(0px);
    }
    30% {
        opacity: 0;
        -moz-transform: translateX(50px);
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes leftToRight {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 0;
        -webkit-transform: translateX(-50px);
    }
    10% {
        opacity: 1;
        -webkit-transform: translateX(0px);
    }
    25% {
        opacity: 1;
        -webkit-transform: translateX(0px);
    }
    30% {
        opacity: 0;
        -webkit-transform: translateX(50px);
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@-ms-keyframes leftToRight {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 0;
        -ms-transform: translateX(-50px);
    }
    10% {
        opacity: 1;
        -ms-transform: translateX(0px);
    }
    25% {
        opacity: 1;
        -ms-transform: translateX(0px);
    }
    30% {
        opacity: 0;
        -ms-transform: translateX(50px);
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
} */

.text-spin, .text-spin2 {
    /* color: red; */
    /* background-color: red;
    padding: 0 10px; */

    white-space: nowrap;
    opacity: 1;
    padding: 0;
    background: none;
    /* background-size: auto 1em; */
    background-size: auto 1.2em;
    background-repeat: repeat-x;
    background-position: center 50%;
    /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000' height='89.68'%3E%3Cpath fill='%2361daff' d='M0 0h1000v89.68H0z'/%3E%3C/svg%3E"); */
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35.67' height='89.68'%3E%3Cpath d='M35.67 0h-9.36C11 2.08 6.36 6.35 6.36 10.35v5.75c0 7.85 2.85 14 2.85 21.84 0 1.56-2.16 5.37-2.85 6.9-2.1 4.62-12.36 8.25-1.3 13.65C10.67 61.23.66 65.44.66 69c0 4 2.85 8.61 2.85 12.65 0 2.62.09 5.27 11.4 8.05h20.76z' fill='%2300baff' fill-rule='evenodd'/%3E%3C/svg%3E"),url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000' height='89.68'%3E%3Cpath fill='%2300baff' d='M0 0h1000v89.68H0z'/%3E%3C/svg%3E");

    /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000' height='89.68'%3E%3Cpath fill='%2300baff' d='M0 0h1000v89.68H0z'/%3E%3C/svg%3E"); */
}

.text-spin:after {
    content: "";
    animation: ChangeText 5s linear infinite;
}

.text-spin p {
    animation: ChangeText 5s linear infinite;
}

.text-spin2:after {
    content: "";
    animation: ChangeText2 5s linear infinite;
}

.text-spin2 p {
    animation: ChangeText2 5s linear infinite;
}

@keyframes ChangeText {
    0% {
        content: "Traffic";
    }

    /* 10% {
        content: "dolor";
    }

    20% {
        content: "sit";
    }

    30% {
        content: "amet";
    }

    40% {
        content: "consectetur";
    } */

    50% {
        content: "Rank";
    }

    /* 60% {
        content: "elit";
    }

    70% {
        content: "Hic";
    }

    80% {
        content: "atque";
    }

    90% {
        content: "Visibility";
    } */

    100% {
        content: "Visibility";
    }
}

@keyframes ChangeText2 {
    0% {
        content: "Yahoo Finance";
    }
    12.5% {
        content: "MSN";
    }
    25% {
        content: "Business Insider";
    }
    37.5% {
        content: "Newsmax";
    }
    50% {
        content: "Benzinga";
    }
    62.5% {
        content: "AsiaOne";
    }
    75% {
        content: "Fox News";
    }
    100% {
        content: "+ 400 Global Media Sites";
    }
}

/**before**/
.text-spin.styled.aqua:before, .text-spin2.styled.aqua:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35.67' height='89.68'%3E%3Cpath d='M35.67 0h-9.36C11 2.08 6.36 6.35 6.36 10.35v5.75c0 7.85 2.85 14 2.85 21.84 0 1.56-2.16 5.37-2.85 6.9-2.1 4.62-12.36 8.25-1.3 13.65C10.67 61.23.66 65.44.66 69c0 4 2.85 8.61 2.85 12.65 0 2.62.09 5.27 11.4 8.05h20.76z' fill='%234c40ed' fill-rule='evenodd'/%3E%3C/svg%3E"),url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000' height='89.68'%3E%3Cpath fill='%234c40ed' d='M0 0h1000v89.68H0z'/%3E%3C/svg%3E")
}

.text-spin.styled:before, .text-spin2.styled:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35.67' height='89.68'%3E%3Cpath d='M35.67 0h-9.36C11 2.08 6.36 6.35 6.36 10.35v5.75c0 7.85 2.85 14 2.85 21.84 0 1.56-2.16 5.37-2.85 6.9-2.1 4.62-12.36 8.25-1.3 13.65C10.67 61.23.66 65.44.66 69c0 4 2.85 8.61 2.85 12.65 0 2.62.09 5.27 11.4 8.05h20.76z' fill='%2361daff' fill-rule='evenodd'/%3E%3C/svg%3E"),url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000' height='89.68'%3E%3Cpath fill='%2361daff' d='M0 0h1000v89.68H0z'/%3E%3C/svg%3E")
}

@supports (--css: variables) {
    .text-spin.styled:before {
        margin-left:calc(-0.007ch*var(--mark-w, 100) - -0.003ch*var(--mark-indent, 0));
        padding-right: calc(0.007ch*var(--mark-w, 100) + -0.003ch*var(--mark-indent, 0));
        background-position: left 0 top calc(50% + 0.1%*var(--mark-offset, 0)),left calc(0.01em*var(--mark-h, 100)*36/90 - 1px) top calc(50% + 0.1%*var(--mark-offset, 0))
    }
}

.text-spin.styled:before, .text-spin2.styled:before {
    margin-left: -.7ch;
    padding-right: .7ch;
    background-repeat: no-repeat,no-repeat;
    background-size: inherit;
    background-position: left 0 top 50%,left calc(.4em - 1px) top 50%
}

.text-spin.styled:after,.text-spin.styled:before, .text-spin2.styled:after,.text-spin2.styled:before {
    display: inline;
    color: white;
    content: "\00FEFF";
    position: relative;
    /* z-index: -1 */
}
/**before**/


/**after **/
.text-spin.styled.aqua:after, .text-spin2.styled.aqua:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40.25' height='89.68'%3E%3Cpath d='M26 32.19c0-1.7 14.25-7.5 14.25-9.19 0-1.46-19.95-2.45-19.95-3.91 0-2.29 17.1-7.4 17.1-9.89C37.4 5.33 41 2 20.3 0H0v89.68h20.3c4.09 0 8.3-4.15 8.3-6.44 0-1.64-8.3-4.41-8.3-5.35 0-3.09 14.25-5.77 14.25-8.91 0-1.09-14.25-2.69-14.25-3.79 0-4 11.4-9.47 11.4-13.45 0-3.7-11.4-5.89-11.4-9.59 0-4.5 5.7-5.46 5.7-9.96z' fill='%234c40ed' fill-rule='evenodd'/%3E%3C/svg%3E"),url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000' height='89.68'%3E%3Cpath fill='%234c40ed' d='M0 0h1000v89.68H0z'/%3E%3C/svg%3E")
}

.text-spin.styled:after, .text-spin2.styled:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40.25' height='89.68'%3E%3Cpath d='M26 32.19c0-1.7 14.25-7.5 14.25-9.19 0-1.46-19.95-2.45-19.95-3.91 0-2.29 17.1-7.4 17.1-9.89C37.4 5.33 41 2 20.3 0H0v89.68h20.3c4.09 0 8.3-4.15 8.3-6.44 0-1.64-8.3-4.41-8.3-5.35 0-3.09 14.25-5.77 14.25-8.91 0-1.09-14.25-2.69-14.25-3.79 0-4 11.4-9.47 11.4-13.45 0-3.7-11.4-5.89-11.4-9.59 0-4.5 5.7-5.46 5.7-9.96z' fill='%2361daff' fill-rule='evenodd'/%3E%3C/svg%3E"),url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000' height='89.68'%3E%3Cpath fill='%2361daff' d='M0 0h1000v89.68H0z'/%3E%3C/svg%3E")
}

@supports (--css: variables) {
    .text-spin.styled:after {
        margin-right:calc(-0.007ch*var(--mark-w, 100) + -0.003ch*var(--mark-indent, 0));
        padding-right: calc(0.007ch*var(--mark-w, 100) - -0.003ch*var(--mark-indent, 0));
        background-position: right 0 top calc(50% + 0.1%*var(--mark-offset, 0)),right calc(0.01em*var(--mark-h, 100)*40/90 - 1px) top calc(50% + 0.1%*var(--mark-offset, 0))
    }
}

.text-spin.styled:after, .text-spin2.styled:after {
    margin-right: -.7ch;
    padding-right: .7ch;
    background-repeat: no-repeat,no-repeat;
    background-size: inherit;
    background-position: right 0 top 50%,right calc(.44444em - 1px) top 50%
}

.text-spin.styled:after,.text-spin.styled:before, .text-spin2.styled:after,.text-spin2.styled:before {
    display: inline;
    color: white;
    content: "\00FEFF";
    position: relative;
    /* z-index: -1 */
}
/**after **/