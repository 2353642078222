/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.btn-logout {
  border: 0;
  background: transparent;
  color: #858585;
  width: 100%;
  padding: 0;
  text-align: start;
}

.btn-logout:hover {
  color: #4c40ed;
}

/* .btnaddfeature {
  border: 0.5px solid #4c40ed;
  font-size: 20px;
  font-weight: bold;
  padding: 0px 10px;
  color: #4c40ed;
  border-radius: 8px;
}
.btnaddfeature:hover {
  color: #fff;
  background: #4c40ed;
} */

.btnaddfeature {
  background: #f7f7ff;
  border: 1px solid #4c40ed;
  font-size: 13px;
  /* font-weight: bold; */
  padding: 6px 12px;
  font-weight: 500;
  color: #4c40ed;
  border-radius: 4px;
}

.btnaddfeature:hover {
  color: #fff;
  background-color: #4c40ed;
}

.btnremovefeature {
  background: #fff5f5;
  border: 1px solid #f82424;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 13px;
  font-weight: 500;
  color: #f82424;
}

.btnremovefeature:hover {
  background-color: #ff4949;
  color: #fff;
}


.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 8px;
  border-color: #dcdcdc;
  color: #333;
  font-size: 15px;
  min-height: 42px;
  padding: 6px 15px;

  /* display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out; */
}

.form-control-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 8px;
  border-color: #dcdcdc;
  color: #333;
  font-size: 15px;
  min-height: 42px;
  padding: 6px 15px;

  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control-select:focus-visible {
  border-color: #bbb;
  box-shadow: none;
  outline: 0;
}

.form-wrap.form-builder .stage-wrap.empty {
  /* border: 3px dashed #ccc; */
  /* background-color: rgba(255, 255, 255, .25); */
  border: 1px solid #c5c5c5;
  border-radius: 0 0 5px 5px;
  /* box-shadow: inset 0 0 0 1px #c5c5c5; */
  margin-right: 20px;
  margin-bottom: 3px;
}

.field-label {
  display: inline !important;
}

.ui-sortable {
  background-color: #f7f7ff;
  padding: 10px !important;
  border-radius: 10px !important;
  margin-right: 10px !important;
}

#fb-editor .form-field {
  margin-bottom: 10px;
}

/* .btn-white:hover {
  background: #fff;
  border: 1px solid #fff;
  box-shadow: inset 0 0 0 #fff
}

.btn-white:hover,
.btn-white.active,
.btn-white:active,
.open>.dropdown-toggle.btn-white {
    background-color: #fff;
    border: 1px solid #fff;
    box-shadow: inset 0 70px 0 0 #fff;
    color: #2229c1;
    -webkit-transition: all .7s;
    -moz-transition: all .7s;
    -o-transition: all .7s;
    transition: all .7s
}

.btn-white.active:not(:disabled):not(.disabled),
.btn-white:active:not(:disabled):not(.disabled),
.show>.btn-white.dropdown-toggle {
    background-color: #f4effd;
    border: 1px solid #f4effd;
    box-shadow: inset 70px 0 0 0 #f4effd;
    color: #74788d;
    -webkit-transition: all .7s;
    -moz-transition: all .7s;
    -o-transition: all .7s;
    transition: all .7s
} */

.client-sections::before {
  background-color: #5347eb;
}

#pressreleaseTable {
  table-layout: "fixed";
}

#pressreleaseTable a {
  color: #4c40ed;
}


@media only screen and (min-width: 768px) {
  .nav.header-navbar-rht.noti-pop-detail {
    min-width: 132px;
    max-width: 132px;
  }
}

.points li {
  padding-left: 25px;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 10px;
}

.points li:before {
  position: absolute;
  /* top: 4px; */
  left: 0;
  content: '\f058';
  font-family: 'FontAwesome';
  color: #4c40ed;
}

.satisfied-client {
  background: #4C40ED;
  padding: 80px 0;
  position: relative;
  z-index: 1;
}

.satisfied-client::before {
  position: absolute;
  content: "";
  /* background: url(/assets/img/bg/premium-bg.png) no-repeat 0 0 / 100%; */
  background: url(../public/assets/img/bg/premium-bg.png) no-repeat 0 0 / 100%;
  transform: scaleX(-1);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: -1;
}

.premium-heading p {
  font-weight: 700;
  font-size: 35px;
  color: #fff;
  margin: 0;
}

@media only screen and (max-width: 768px) {
  .premium-heading p {
    font-weight: 700;
    font-size: 25px;
    color: #fff;
    margin: 0;
  }
}